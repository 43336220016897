.clear-button {
  z-index: 3;
  /* background-color: white; */
  background-color: rgb(17, 23, 29) !important;
  color: white !important;
  border: none;
  border-radius: 100px !important;
  width: 110px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); */
  /* background-color: rgb(17, 23, 29); */
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.4);
}

.clear-button:hover {
  background-color: rgb(22, 28, 35);
}

.clear-button:active {
  background-color: rgb(22, 28, 35);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

.toggle-button {
  z-index: 3;
  background-color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  align-items: center;
}

.toggle-button:active {
  background-color: rgb(22, 28, 35);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

.tool-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 10px;
  pointer-events: auto;
}