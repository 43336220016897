body {
  margin: 0;
  padding: 0;
  height: 100dvh;
  overflow: auto;
  font-size: 14px;
}

.container {
  position: relative;
  width: 100dvw;
  height: 100dvh;
  overflow: auto;
}

.map-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

.overlay-container {
  max-height: 100%;
  z-index: 3;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-105%);
  overflow-y: auto;
  pointer-events: auto;
}

.overlay-container.visible {
  transform: translateX(0);
  overflow-y: scroll;
  pointer-events: auto;
}

.left-container {
  display: flex;
  z-index: 2;
  flex-direction: column;
  width: 33.33%;
  height: 100%; /* Subtracting the margin-top */
  padding: 10px;
  padding-bottom: 20px; /* Added extra padding at the bottom */
  box-sizing: border-box;
  pointer-events: auto;
  overflow-y: visible;
}

.top {
  justify-content: flex-start;
  z-index: 3;
  max-height: 90%;
  pointer-events: none;
}

.box {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  margin: 5px;
  margin-bottom: 10px;
  overflow: auto;
  padding: 10px;
  max-height: 70vh;
  flex-direction: column;
}

h2 {
  margin-top: -2px;
  padding-left: 5px;
  color: rgb(45,47,55);
  font-size: 1.2em;
  margin-bottom: 0.5em;
}

.highlight {
  background-color: yellow;
}

.no-border {
  border: none !important;
}

.min-width {
  min-width: 30px;
  width: 1%;
  white-space: nowrap;
}

.MuiTableCell-root {
  padding: 6px 8px;
  font-size: 0.8rem;
}

.MuiButton-root {
  padding: 4px 8px;
  font-size: 0.8rem;
}

.MuiTextField-root {
  margin-bottom: 8px;
}

.MuiInputBase-input {
  padding: 8px 12px;
  font-size: 0.9rem;
}

.add-row-container {
  margin-top: 5px;
}

.calculate-box {
  padding: 10px;
  max-height: none;
}

@media (max-width: 768px) {
  .left-container {
    width: 100%;
    margin-top: 0vh; /* Reduced from 5vh to give more space for content */
    max-height: calc(100vh - 0vh); /* Adjusted to account for margin-top */
    height: 100vh;
  } 
  .top {
    justify-content: flex-start;
    z-index: 3;
    max-height: 90%;
  }
}

.rotating-word {
  display: inline-block;
  animation: fade 5s infinite;
}

@keyframes fade {
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
}
